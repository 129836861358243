<template>
  <section
    v-if="validCustomer && validCustomer.length"
    class="container slider-about-top"
  >
    <div class="title-top-slide-about">
      <div class="d-flex align-items-center gap-15">
        <span>
          <svg
            width="39"
            height="33"
            viewBox="0 0 39 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.6086 5.74549C33.5044 8.01302 38.1358 10.5073 38.8874 13.875C39.6391 17.2343 36.5031 21.4586 33.561 24.9103C30.619 28.362 27.8709 31.0326 24.6621 32.1328C21.4533 33.2329 17.7838 32.7626 14.5507 31.3937C11.3177 30.0164 8.52113 27.7489 6.18526 24.6667C3.84939 21.5762 1.97423 17.671 2.04697 13.5727C2.11163 9.47431 4.11611 5.18281 7.32491 2.78931C10.5337 0.395808 14.9549 -0.0996878 18.7456 0.672951C22.5364 1.44559 25.7047 3.47796 29.6086 5.74549Z"
              fill="#EEEEEE"
            />
            <path
              d="M15.4997 22.8552C14.983 22.8552 14.4663 22.8164 13.9755 22.726C11.2372 22.3256 8.74426 20.7498 7.16842 18.4118C6.07051 16.7585 5.48926 14.8339 5.48926 12.8448C5.48926 7.32935 9.98426 2.83435 15.4997 2.83435C21.0151 2.83435 25.5101 7.32935 25.5101 12.8448C25.5101 14.8339 24.9288 16.7585 23.8309 18.4118C22.2422 20.7627 19.7493 22.3256 16.9851 22.7389C16.533 22.8164 16.0163 22.8552 15.4997 22.8552ZM15.4997 4.77185C11.0434 4.77185 7.42676 8.38852 7.42676 12.8448C7.42676 14.4594 7.89176 16.0094 8.77009 17.3269C10.0488 19.2127 12.0509 20.4785 14.2726 20.8014C15.0993 20.9435 15.913 20.9435 16.6751 20.8014C18.9355 20.4785 20.9376 19.1998 22.2163 17.3139C23.0947 15.9964 23.5597 14.4464 23.5597 12.8318C23.5726 8.3885 19.9559 4.77185 15.4997 4.77185Z"
              fill="#EB6FA7"
            />
            <path
              d="M8.35646 30.3984C8.17563 30.3984 8.00771 30.3726 7.82688 30.3338C6.98729 30.1401 6.34146 29.4942 6.14771 28.6546L5.69563 26.7559C5.66979 26.6396 5.57938 26.5492 5.45021 26.5105L3.31896 26.0067C2.51813 25.813 1.88521 25.2188 1.66563 24.4309C1.44604 23.643 1.66563 22.7905 2.24688 22.2092L7.28438 17.1717C7.49104 16.9651 7.77521 16.8617 8.05938 16.8876C8.34354 16.9134 8.60188 17.0684 8.76979 17.3138C10.0485 19.1996 12.0506 20.4784 14.2852 20.8013C15.1119 20.9434 15.9256 20.9434 16.6877 20.8013C18.9481 20.4784 20.9502 19.1996 22.229 17.3138C22.384 17.0684 22.6552 16.9134 22.9394 16.8876C23.2235 16.8617 23.5077 16.9651 23.7144 17.1717L28.7519 22.2092C29.3331 22.7905 29.5527 23.643 29.3331 24.4309C29.1135 25.2188 28.4677 25.8259 27.6798 26.0067L25.5485 26.5105C25.4323 26.5363 25.3419 26.6267 25.3031 26.7559L24.851 28.6546C24.6573 29.4942 24.0115 30.1401 23.1719 30.3338C22.3323 30.5405 21.4669 30.2434 20.9244 29.5846L15.4994 23.3459L10.0744 29.5976C9.63521 30.1142 9.01521 30.3984 8.35646 30.3984ZM7.86563 19.3417L3.61604 23.5913C3.49979 23.7076 3.51271 23.8367 3.53854 23.9142C3.55146 23.9788 3.61604 24.108 3.77104 24.1338L5.90229 24.6376C6.74188 24.8313 7.38771 25.4771 7.58146 26.3167L8.03354 28.2155C8.07229 28.3834 8.20146 28.4351 8.27896 28.4609C8.35646 28.4738 8.48563 28.4867 8.60188 28.3576L13.549 22.6613C11.3531 22.2351 9.33813 21.0596 7.86563 19.3417ZM17.4498 22.6484L22.3969 28.3317C22.5131 28.4738 22.6552 28.4738 22.7327 28.448C22.8102 28.435 22.9265 28.3705 22.9781 28.2026L23.4302 26.3038C23.624 25.4642 24.2698 24.8184 25.1094 24.6246L27.2406 24.1209C27.3956 24.0822 27.4602 23.9659 27.4731 23.9013C27.499 23.8367 27.5119 23.6946 27.3956 23.5784L23.146 19.3288C21.6606 21.0467 19.6585 22.2221 17.4498 22.6484Z"
              fill="#EB6FA7"
            />
            <path
              d="M17.9414 17.8693C17.6056 17.8693 17.2051 17.7788 16.7272 17.4947L15.5001 16.7584L14.2731 17.4818C13.1493 18.1534 12.4131 17.7659 12.1418 17.5722C11.8706 17.3784 11.2893 16.7972 11.5864 15.5184L11.8964 14.188L10.8631 13.2322C10.2947 12.6638 10.0881 11.9793 10.2818 11.3593C10.4756 10.7393 11.0439 10.3001 11.8447 10.1709L13.2268 9.93843L13.8856 8.49177C14.2601 7.75552 14.8414 7.34216 15.5001 7.34216C16.1589 7.34216 16.7531 7.76844 17.1147 8.50469L17.8768 10.0289L19.1556 10.1838C19.9435 10.313 20.5118 10.7522 20.7185 11.3722C20.9122 11.9922 20.7056 12.6768 20.1372 13.2451L19.0651 14.3172L19.401 15.5184C19.6981 16.7972 19.1168 17.3784 18.8456 17.5722C18.7035 17.6884 18.3935 17.8693 17.9414 17.8693ZM12.4131 12.0568L13.3043 12.948C13.7176 13.3613 13.9243 14.0588 13.7951 14.6272L13.5497 15.6605L14.5831 15.0534C15.1385 14.7305 15.8876 14.7305 16.4301 15.0534L17.4635 15.6605L17.231 14.6272C17.1018 14.0459 17.2956 13.3613 17.7089 12.948L18.6001 12.0568L17.4764 11.863C16.9339 11.7726 16.3914 11.3722 16.146 10.8814L15.5001 9.61551L14.8543 10.9072C14.6218 11.3851 14.0793 11.7984 13.5368 11.8889L12.4131 12.0568Z"
              fill="#EB6FA7"
            />
          </svg>
        </span>
        <div>
          <h5 class="font-bold">
            <span class="font-bold fontsize-20 weight-bold">
              مشتریان معتبر
            </span>
            <span> بندیتو </span>
          </h5>
          <p class="mb-0 line-height2 text-color-666 fontsize14 text-justify">
            درمورد محصولات بندیتو چه میگویند:
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="swiper-pagination"></div> -->

    <div
      id="slider-about-top"
      class="swiper-container overflow-hidden w-100 position-relative mt-3"
    >
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in validCustomer"
          :key="index"
        >
          <a
            class="d-flex align-items-center flex-column w-100"
            :href="item.link"
          >
            <img
              class="image-top-slid-about"
              height="100"
              width="200"
              :src="item.image"
              alt=""
            />
            <h5 class="text-color-grey fontsize-medium font-w-700 m-0 pt-3 text-center">{{ item.name }}</h5>
            <p class="text-color-grey font-w-100 m-0 pt-2 text-center">{{ item.description }}</p>
          </a>
        </div>
      </div>
      <div class="swiper-button-next">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="arrow-slie"
            d="M3.56577 8.98206H14.3838"
            stroke="white"
            stroke-width="1.4424"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            class="arrow-slie"
            d="M7.92871 4.63733L3.56544 8.98184L7.92871 13.3271"
            stroke="white"
            stroke-width="1.4424"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="swiper-button-prev">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="arrow-slie"
            d="M14.4342 8.98206H3.61621"
            stroke="white"
            stroke-width="1.4424"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            class="arrow-slie"
            d="M10.0713 4.63733L14.4346 8.98184L10.0713 13.3271"
            stroke="white"
            stroke-width="1.4424"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper/swiper-bundle.min";

export default {
  components: {},
  inject: ["mediaQueries"],
  mounted() {
    this.$store.dispatch("front/getValidCustomerFromServer");
    this.initRelatedProducts();
  },
  computed: {
    validCustomer() {
      if (this.$store.getters["front/getValidCustomers"] == null) {
        return [];
      } else {
        return this.$store.getters["front/getValidCustomers"];
      }
    },
  },
  watch: {
    validCustomer(newValue) {
      if (newValue) {
        this.initRelatedProducts(true);
      }
    },
  },
  methods: {
    initRelatedProducts(kill = false) {
      this.initSwiper("asliderAbout", kill, () => {
        let swiper = new Swiper(".slider-about-top .swiper-container", {
          spaceBetween: 100,
          loop: false,
          autoplay:false,
          speed: 500,
          centeredSlides: this.mediaQueries.mobileSize ? true : false,
          centeredSlidesBounds: this.mediaQueries.mobileSize ? true : false,
          // pagination: {
          //   el: ".swiper-pagination",
          // },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            300: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            500: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 10,
            },
          },
        });
      });
    },
  },
};
</script>

<style>
.slider-about-top .swiper-button-disabled {
  background-color: #fff !important;
  box-shadow: 0px 0px 5px gray;
}
.slider-about-top .swiper-button-disabled .arrow-slie {
  stroke: #000 !important;
}
.slider-about-top .swiper-slide-active .image-top-slid-about {
  stroke: #000 !important;
}
.slider-about-top .swiper-button-prev:after,
.slider-about-top .swiper-button-next:after {
  display: none;
}
.slider-about-top .swiper-button-prev,
.slider-about-top .swiper-button-next {
  background: var(--color-theme);
  box-shadow: 0px 0px 10px var(--color-theme);
  width: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
#slider-about-top .swiper-slide{
  border-radius: 20px;
    padding: 2rem 1rem 1rem 1rem;
    background: rgb(255,255,255);
    background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(235, 111, 167, 0.32931722689075626) 85%
  );    /* background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(235,111,167,0.19931722689075626) 90%); */
}

</style>
