<template>
  <div v-if="false" id="freeShippingProgressBar">
    <p id="text" v-if="calc >= 0 && !cartHasFreeShippingItem">
      <span>{{ calc | price }}</span>
      <span>تومان</span>
      تا <b>رایگان</b> شدن ارسال این سفارش !
    </p>
    <p id="success" v-else-if="$route.path != '/cart'">
      تبریک ارسال شما <b>رایگان</b> شد
    </p>
    <div v-if="calc >= 0 && !cartHasFreeShippingItem" id="progressBar">
      <div :style="`width:${width}%`"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["totalPrice"],
  computed: {
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    width() {
      return (
        (this.totalPrice * 100) /
        Number(this.pishtaz ? this.pishtaz.free_threshold : 0)
      );
    },
    cartHasFreeShippingItem() {
      return this.$store.getters["front/getCartHasFreeShippingItem"];
    },
    freeProducts() {
      return this.$store.getters["front/getFreeShippingProducts"];
    },

    pishtaz() {
      if (this.$store.getters["front/getShippings"].length) {
        let pishtaz = this.$store.getters["front/getShippings"].find(
          (item) => item.id == 3
        );
        if (pishtaz) {
          return pishtaz;
        }

        return 0;
      }
    },
    calc() {
      return this.pishtaz
        ? Number(this.pishtaz ? this.pishtaz.free_threshold : 0) -
            Number(this.totalPrice)
        : 0;
    },
  },
  created() {
    this.$store.dispatch("front/getShippingsFromServer");
    if (!this.freeProducts) {
      this.$store.dispatch("front/getFreeShippingProductsFromDB");
    }
  },
};
</script>
<style>
div#progressBar {
  width: 96%;
  background: #808080a6;
  border-radius: 15px;
  height: 5px;
  margin: 8px auto;
}
div#progressBar div {
  background: linear-gradient(rgba(226, 102, 76, 0.65), transparent),
    repeating-linear-gradient(135deg, #992258 0 0.25em, #f172ac 0 0.5em);
  height: 5px;
  border-radius: 15px;
}
#text span,
#success b {
  color: var(--color-theme);
}
#freeShippingProgressBar {
  width: 100%;
}
#freeShippingProgressBar p {
  font-size: 15px;
  margin: 8px auto;
  width: 95%;
  margin-bottom: 0;
}
</style>
