<template>
  <!-- <button
      :id="atlasTheme==true?'loginIcon1':'loginIcon'"
      @click.prevent="showOptions = !showOptions"
      :class="showOptions ? 'border-radius-active' : 'border-r-20'"
      class="logged-in-btn d-md-flex align-items-center gap-2 padding-6 d-none bg-color-theme2 pl-2 text-white"> -->
  <!-- <div  class="d-inline-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="#ffffff"
          class="bi bi-person"
          viewBox="0 0 16 16"
        >
          <path
            fill="#ffffff"
            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
          />
        </svg>
      </div> -->
  <!-- <span
        v-if="FullName != null"
        class="fontsize-small text-white mx-1"
      >
        {{ FullName }}</span
      > -->
  <!-- <span v-else class="fontsize13 text-white mx-1">حساب کاربری</span> -->
  <!-- <svg
        width="13"
        height="11"
        viewBox="0 0 24 24"
        fill="#ffffff"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.869 16.6308C10.811 16.5743 10.563 16.3609 10.359 16.1622C9.076 14.9971 6.976 11.9576 6.335 10.3668C6.232 10.1252 6.014 9.51437 6 9.18802C6 8.8753 6.072 8.5772 6.218 8.29274C6.422 7.93814 6.743 7.65368 7.122 7.49781C7.385 7.39747 8.172 7.2416 8.186 7.2416C9.047 7.08573 10.446 7 11.992 7C13.465 7 14.807 7.08573 15.681 7.21335C15.695 7.22796 16.673 7.38383 17.008 7.55431C17.62 7.86702 18 8.47784 18 9.13151V9.18802C17.985 9.61374 17.605 10.509 17.591 10.509C16.949 12.0141 14.952 14.9834 13.625 16.1768C13.625 16.1768 13.284 16.5129 13.071 16.659C12.765 16.887 12.386 17 12.007 17C11.584 17 11.19 16.8724 10.869 16.6308Z"
          fill="#ffffff"
        />
      </svg> -->

  <!-- </button> -->
  <div
    class="d-flex align-items-center gap-5 position-relative cursor-pointer"
    @click.prevent="showOptions = !showOptions"
  >
    <router-link
      class="d-block d-xl-none avatar rounded-circle"
      to="/user-panel"
    >
      <template v-if="user && user.image">
        <img
          class="rounded-circle"
          width="50"
          :src="user.image.url"
          alt="user profile image"
        />
      </template>
      <template v-else>
        <img
          class="rounded-circle"
          width="50"
          src="../../../assets//images/userDefault.jpg"
          alt="user profile default image"
        />
      </template>
    </router-link>
    <div class="avatar rounded-circle d-none d-xl-block">
      <template v-if="user && user.image">
        <img
          class="rounded-circle"
          width="50"
          :src="user.image.url"
          alt="user profile image"
        />
      </template>
      <template v-else>
        <img
          class="rounded-circle"
          width="50"
          src="../../../assets//images/userDefault.jpg"
          alt="user profile default image"
        />
      </template>
    </div>
    <div
      class="user-profile d-none d-xl-flex align-items-start flex-column gap-5"
    >
      <p class="text-color-grey fontsize-mini m-0">خوش آمدید!</p>
      <template v-if="user && user.full_name">
        <p class="m-0 text-color-grey fontsize-small font-w-900">
          {{ user.full_name }}
        </p>
      </template>
      <template v-else-if="user">
        <p class="m-0 text-color-grey fontsize-small font-w-900">
          {{ user.mobile }}
        </p>
      </template>
    </div>
    <transition name="fade">
      <div v-if="showOptions" id="loginUserOptions" class="mt-2">
        <router-link
          class="user-panel-option w-s-nowrap text-center"
          to="/user-panel"
          @click="gotoUserPanel()"
          :class="{ disabledLogout: disabledlLogout }"
          >مشاهده حساب کاربری</router-link
        >
        <router-link
          class="text-center"
          to="/club-panel"
          :class="{ disabledLogout: disabledlLogout }"
          >باشگاه مشتریان</router-link
        >
        <button
          class="logout-btn"
          @click="signout()"
          :class="{ disabledLogout: disabledlLogout }"
        >
          خروج <i class="fa fa-signout"></i>
        </button>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    atlasTheme: { Boolean },
  },
  data() {
    return {
      showOptions: false,
      disabledlLogout: false,
    };
  },
  created() {
    if (this.user == null) {
      this.$store.dispatch("front/getUserDetailFromServer");
    }
  },
  methods: {
    gotoUserPanel() {
      this.showOptions = false;
    },
    signout() {
      window
        .swal({
          title: "آیا میخواهید خارج شوید؟",

          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله خارج شو"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.disabledlLogout = true;
            this.$axios.post("/customer/logout").then((res) => {
              this.disabledlLogout = false;
              this.showOptions = false;
              // پاک کردن اطلاعات مربوط به کاربر از کوکی و ویو ایکس
              // this.$emit("singOut",null);
      this.$store.commit("front/setGamePrice", null);
              
              this.$root.deleteCookie("Authorization");
              delete this.$axios.defaults.headers.common["Authorization"];
              this.$store.commit("front/setLoginStatus", false);
              if (this.cart) {
                this.cart.emptyCookie();
              }
            });
          }
        });
    },
  },
  computed: {
    // دریافت اطلاعات کاربر
    // FullName() {
    //   return this.$store.getters["front/getFullName"];
    // },
    cart() {
      if (this.$store.getters["front/getHomeData"]) {
        return this.$store.getters["front/getHomeData"].user.cart;
      } else {
        return null;
      }
    },

    user() {
      return this.$store.getters["front/getUserDetail"];
    },
    status() {
      return this.$store.getters["front/getUserDetailStatus"];
    },
  },
};
</script>
<style scoped>
#loginIcon.logged-in-btn div {
  background-color: var(--color-themepinky);
  /*border-radius: 0 0 10px 10px;*/
  -webkit-clip-path: none;
  clip-path: none;
}

#loginUserOptions {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: fit-content;
  top: 100%;
  right: 0;
  z-index: 10;
  border-radius: 20px;
}
#loginUserOptions button,
#loginUserOptions a {
  padding: 10px;
  background-color: var(--color-themepinky);
  color: #ffffff !important;
  transition: all 1s;
}
#loginUserOptions button:hover,
#loginUserOptions a:hover {
  background-color: var(--color-theme);
  color: white !important;
}

@media (max-width: 768px) {
  #loginUserOptions {
    display: none;
  }
}
.disabledLogout {
  pointer-events: none;
  opacity: 0.5;
}
#showOptionBtn {
  position: relative;
}
#loginIcon1.logged-in-btn div {
  -webkit-clip-path: none;
  clip-path: none;
}
#loginUserOptions .user-panel-option {
  border-radius: 10px 10px 0 0;
}
a {
  font-size: 14px;
}
.bi-person {
  transform: translateY(-2px);
}

.logout-btn {
  border-radius: 0 0 10px 10px;
}

.logged-in-btn div {
  background-color: var(--color-themepinky);

  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
}
.logged-in-btn {
  position: relative;
  background-color: var(--color-themepinky);

  min-width: 137px;
}
.border-radius-active {
  border-radius: 20px 20px 0 0;
}
</style>
