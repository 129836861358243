import { render, staticRenderFns } from "./DynamicLinker.vue?vue&type=template&id=574a6aef&scoped=true"
import script from "./DynamicLinker.vue?vue&type=script&lang=js"
export * from "./DynamicLinker.vue?vue&type=script&lang=js"
import style0 from "./DynamicLinker.vue?vue&type=style&index=0&id=574a6aef&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574a6aef",
  null
  
)

export default component.exports